<template>
    <file-manager class="file-manager-height" :settings="settings"></file-manager>
</template>

<script>
export default {
    computed: {
    settings() {
        const userData = JSON.parse(localStorage.getItem('userData'));
      return {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${userData.access_token}`,
        },
        baseUrl: `${process.env.VUE_APP_ROOT_API_FILE_MANAGER}`, // overwrite base url Axios
        lang: this.$i18n.locale, 
      };
    },
  },
}
</script>
<style>
.file-manager-height{
    height: 750px !important;
}
</style>